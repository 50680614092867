const revealBtn = document.getElementById("reveal-btn");
const prizeModal = document.getElementById("optin-container");
const revealOptinBtn = document.getElementById("reveal-optin-btn");
const optinClose = document.getElementById("optin-close");
const optinBG = document.getElementById("optin-bg");
const claimPromo = document.getElementById("claim-promo");
const loadingIcon = document.getElementById("loading-icon");
const calBtnCta = document.getElementById("cal-btn-cta");
const generalTermsLink = document.getElementById("general-link");
const promoTermsContainer = document.getElementById("promo-terms-container");


revealBtn?.addEventListener("click", revealPrizeContent);
function revealPrizeContent() {
  prizeModal.classList.add("show");
  promoTermsContainer.classList.add("claimed");
}

revealOptinBtn?.addEventListener("click", revealOptinModal);
function revealOptinModal() {
  prizeModal.classList.add("optin", "show");
  promoTermsContainer.classList.add("claimed");
}

optinClose?.addEventListener("click", closeOptinModal);
generalTermsLink?.addEventListener("click", closeOptinModal);
optinBG?.addEventListener("click", closeOptinModal);
function closeOptinModal() {
  prizeModal.classList.remove("optin", "show");
}

function claimBtnActivated() {
  if (claimPromo.getAttribute('data-type') === "DEPOSIT") {
		claimPromo.remove();

      calBtnCta.innerHTML = depositText;
		calBtnCta.classList.add("active", "cashier-link");

  } else if (claimPromo.getAttribute('data-type') === "BONANZAGAME") {
    claimPromo.remove();
	 calBtnCta.classList.add("active");
    calBtnCta.innerHTML = playText;
    $(calBtnCta).prop("href", "/play/pmsweetbonanza");

  } else if (claimPromo.getAttribute('data-type') === "OLYMPUS100GAME") {
    claimPromo.remove();
	 calBtnCta.classList.add("active");
    calBtnCta.innerHTML = playText;
    $(calBtnCta).prop("href", "/play/pmgatesofolympus1000");

  } else if (claimPromo.getAttribute('data-type') === "LODGAME") {
      claimPromo.remove();
		calBtnCta.classList.add("active");
      calBtnCta.innerHTML = playText;
      $(calBtnCta).prop("href", "/play/pnglegacyofdead");
  
  } else if (claimPromo.getAttribute('data-type') === "BBSGAME") {
      claimPromo.remove();
		calBtnCta.classList.add("active");
      calBtnCta.innerHTML = playText;
      $(calBtnCta).prop("href", "/play/pmbigbasssplash");
  
  } else {
	 calBtnCta.classList.add("active", "wz-cal-btn-claimed");
    calBtnCta.innerHTML = claimedText;
  }
}

function claimPrize(event) {

  loadingIcon.classList.add("loading");
  claimPromo.style.display = "none";

  let optimoveCode = event.target.getAttribute("data-optimove");
  let backofficeCampaignID = parseInt(event.target.getAttribute("data-campaign-id"));

  var payload = { 'campaign' : backofficeCampaignID };

	callPlatformAPI('campaigns/add-user', payload).then(function(r) {
		if (r.added){
			let parameter = document.body.getAttribute('data-route-id') || 'REPETITIVE_CALENDAR';
			$.ajax({
				method: "POST",
				url: "/ajax/ajaxhandler.php?class=rest&method=setClaimPrize&parameters="+parameter,
			}).done((data, status) => {
				loadingIcon.classList.remove("loading");
				claimBtnActivated();
				if (data.status == true) {
					if (typeof optimoveSDK != "undefined") {
						optimoveSDK.API.reportEvent('prize_claim', {"prize_code": optimoveCode});
					};
				}
			});
		} else {
			console.log("Campaign Error");
		}
	});

	if (typeof gtag !== "undefined") {
      let eventCategory = "recurring calendar";
      let eventLabel = optimoveCode + "-" + basicInfo.player.id;
      gtag("event", "claim", {
        event_category: eventCategory,
        event_label: eventLabel,
      });
   }
}

function replaceTextWithCurrentDateByClass(className) {
	let promoContainers = document.querySelectorAll('.wz-cal_acc-item');
	promoContainers.forEach( (promoContainer) => {
		let currentDate = new Date();
		if(promoContainer.hasAttribute('data-minusDays')) {
			currentDate.setDate(currentDate.getDate() - promoContainer.getAttribute('data-minusDays'));
		}
		var day = currentDate.getDate();
		var month = currentDate.getMonth() + 1; // Months are zero-based
		var year = currentDate.getFullYear();
		var formattedDate = (day < 10 ? '0' : '') + day + '/' + (month < 10 ? '0' : '') + month + '/' + year;
		promoContainer.querySelectorAll('.'+className).forEach((element) => element.textContent = formattedDate);
	});
}

replaceTextWithCurrentDateByClass('termsCurrentDate');

var wzCalCountDown = (function() {
	var dateTime;
	var day;
	var hour;
	var dayKey;
	var is_language;
	var hHour;

	function update() {
		// Get current date and time
		var today = new Date();

		var d = 0;
		var h = (today.getUTCHours() - 23) * -1;
		var m = ((today.getUTCMinutes() - 60) * -1)-1;
		var s = (today.getUTCSeconds() - 60) * -1;
		if (m.toString() === '60') {
			m = '0';
		}
		if (s.toString() === '60') {
			s = '0';
		}
		if (h.toString() === '24') {
			h = '0';
		}


		switch (is_language) {
			case 'fr':
				dayKey = d + 'j ' + h + 'h ' + m + 'm ' + s + 's'
				hHour = +h + 'h <span class="min">' + m + 'm ' + s + 's</span>'
				break;
			case 'no':
				dayKey = d + 'D ' + h + 't ' + m + 'm ' + s + 's'
				hHour = +h + 't <span class="min">' + m + 'm ' + s + 's</span>'
				break;
			default:
				// code block
				dayKey = d + 'd ' + h + 'h ' + m + 'm ' + s + 's'
				hHour = +h + 'h <span class="min">' + m + 'm ' + s + 's</span>'
		}

		// Return remaining
		return {
			dateTime: dayKey,
			day: d,
			hour: hHour,
		};
	}

	// Run update just after next full second
	function runUpdate() {
		var el = $('.wz-cal-countdown_full');
		var elDay = $('.wz-cal-countdown_day');
		var elHour = $('.wz-cal-countdown_hour');

		el.html(update(is_language).dateTime);
		elDay.html(update(is_language).day);
		elHour.html(update(is_language).hour);
		setTimeout(runUpdate, 1020 - (Date.now() % 1000));
	}

	$(function() {
		// is_language = $('body').attr('data-language')
		is_language = 'en'
		runUpdate();
	});

	return {}

})();


claimPromo?.addEventListener("click", claimPrize);
